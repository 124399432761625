<template>
    <div>
        <iframe :src="frame" width="100%" height="1200px" frameborder="0"></iframe>
    </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import { i18n } from '@/plugins/i18n'
import font from './font.json'
import fontBold from './fontBold.json'
import { formatPrice, sumdate, currentCurrency, sumdatetime } from '@/services/filters'
import 'jspdf-autotable'
import { api } from "@/services/api"

export default {
    setup() {
        const { route } = useRouter()
        const fonts = ref(font)
        const fontsBold = ref(fontBold)
        const dataTableList = ref([])
        const fontTitle = ref(12)
        const fontBody = ref(10)
        const lastTable = ref(0)
        const shopData = ref({})
        const frame = ref('')

        onMounted(() => {
            shopData.value = JSON.parse(localStorage.getItem('shop'))
            api.get({
                path: '/printOrder',
                param: `?order_id_list=${ route.value.query.id }&lang=${ route.value.query.lang }&currency_id=${ localStorage.getItem('currencyId') }`,
            }).then(({ data }) => {
                savePDF(data)
            }).catch((err) => {
                console.log(err)
            })
        })


        const headers = [
            `รายการ`,
            'ราคา',
            'จำนวน',
            'ส่วนลด',
            'รวม',
        ]

        const savePDF = data => {
            const doc = new jsPDF({
                orientation: 'portrait',
                format: [80, 210],
                unit: 'mm',
            })

            doc.setProperties({
                title: `พิมพ์ใบเสร็จรับเงิน`,
            })

            doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
            doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
            doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
            doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
            doc.setFont('TH-Niramit', 'bold')
            for (let i = 0; i < data.length; i++) {
                const alignLeft = 5
                const spacer = 3.5
                let total = 5
                doc.autoTable({
                    theme: 'plain',
                    styles: {
                        font: 'TH-Niramit',
                        fontStyle: 'bold',
                        textColor: '#212121',
                        fontSize: fontBody.value,
                        halign: 'left',
                    },
                    showFoot: 'lastPage',
                    headStyles: {
                        // textColor: '#1E88E5',
                        fontStyle: 'bold',
                        fontSize: fontBody.value,
                    },
                    startY: 48,
                    margin: {
                        top: 48,
                        left: 5,
                        right: 5,
                        bottom: 80,
                    },
                    head: [headers],
                    body: data[i].order_detail_arr,
                    didDrawPage(datax) {
                        doc.setTextColor('#000')
                        doc.setFontSize(12)
                        doc.text(`${ shopData.value.shop_name }`, 4, total, null, null, 'left')
                        doc.setFontSize(fontBody.value)
                        total += spacer
                        doc.text(`ทะเบียนการค้า ${ shopData.value.shop_tax_number }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`ที่อยู่ ${ shopData.value.shop_address } ${ shopData.value.shop_subdistrict }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`${ shopData.value.shop_district } ${ shopData.value.shop_province }  ${ shopData.value.shop_zipcode } `, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`เบอร์โทรศัพท์ ${ shopData.value.shop_phone }`, 4, total)
                        total += spacer
                        doc.text(`วันที่สั่งซื้อ  ${ sumdatetime(data[i].CREATE) }`, 4, total)
                        total += spacer
                        doc.text(`พนักงานขาย  ${ data[i].user_create }`, 4, total)
                        total += spacer - 2
                        doc.setLineWidth(0.2)
                        doc.setDrawColor('#37474F')
                        doc.line(4, total, 76, total)
                        total += spacer
                        doc.text(`ชื่อลูกค้่า : ${ data[i].customer_lname } เลขที่ออเดอร์ : ${ data[i].order_code }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`ชื่อผู้รับสินค้า :  ${ data[i].order_transportation_name }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`เบอร์โทร : ${ data[i].order_transportation_phone }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`เบอร์โทร : ${ data[i].order_transportation_phone }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`ที่อยู่จัดส่ง ${ data[i].order_transportation_address } ${ data[i].order_transportation_subdistrict }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(` ${ data[i].order_transportation_district }  ${ data[i].order_transportation_province }  ${ data[i].order_transportation_zipcode }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.setLineWidth(0.2)
                        doc.setDrawColor('#37474F')
                        doc.line(4, total + 2, 76, total + 2)
                        total += spacer + 2
                        total = datax.cursor.y - 1
                        doc.setDrawColor('#37474F')
                        doc.line(4, total + 0.5, 76, total + 0.5)
                        total += spacer
                        doc.text(`ค่าจัดส่ง`, 4, total, null, null, 'left')
                        doc.text(` ${ data[i].order_transportation_price }`, 76, total, null, null, 'right')
                        total += spacer
                        doc.text(`กล่อง`, 4, total, null, null, 'left')
                        doc.text(` ${ data[i].order_transportation_amount }`, 76, total, null, null, 'right')
                        total += spacer
                        doc.text(`รวม`, 4, total, null, null, 'left')
                        doc.text(` ${ data[i].order_transportation_total }`, 76, total, null, null, 'right')
                        // total += spacer
                        // doc.text(`VAT10%`, 4, total, null, null, 'left')
                        // doc.text(` ${ data[i].order_vat }`, 76, total, null, null, 'right')
                        total += spacer - 3
                        doc.setDrawColor('#37474F')
                        doc.line(4, total + 0.5, 76, total + 0.5)
                        total += spacer
                        const arrs = [
                            { name: 'ราคารวม', value: String(formatPrice(data[i].order_before_discount)) },
                            { name: 'ส่วนลด (%)', value: String(formatPrice(data[i].order_discount)) },
                            { name: 'ราคารวมหลังหักส่วนลด', value: String(formatPrice(data[i].order_after_discount)) },
                            {
                                name: `ภาษีมูลค่าเพิ่ม ${ String(parseInt(data[i].order_vat)) }%`,
                                value: String(formatPrice(data[i].order_vat_value))
                            },
                            { name: 'ราคาสุทธิ', value: String(formatPrice(data[i].sum_total_transportation)) }
                        ]
                        for (let x = 0; x < arrs.length; x++) {
                            doc.text(arrs[x].name, 4, total, null, null, 'left')
                            doc.text(arrs[x].value, 76, total, null, null, 'right')
                            total += spacer
                        }
                        total += spacer - 5
                        doc.setDrawColor('#37474F')
                        doc.line(4, total + 0.5, 76, total + 0.5)
                        total += spacer
                        doc.text(`ชื่อธนาคาร : ${ data[i].bank_full_name || '' }, เลขที่บัญชี : ${ data[i].bank_number || '' }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`จำนวนที่โอน : ${ data[i].order_payment_total ? formatPrice(data[i].order_payment_total) : '' }, ชื่อผู้โอน : ${ data[i].order_payment_slip_confirm || '' }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`เวลาที่โอน : ${ data[i].order_payment_date ? sumdatetime(data[i].order_payment_date) : '' }`, 4, total, null, null, 'left')
                        total += spacer - 3
                        doc.setDrawColor('#37474F')
                        doc.line(4, total + 0.5, 76, total + 0.5)
                        total += spacer
                        doc.text(`ช่องทางการจัดส่ง : ${ data[i].transport_name || '' }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`วันที่จัดส่ง : ${ sumdate(data[i].order_transportation_date) }`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`สินค้าถึงลูกค้า 1 วันหลังจัดส่ง`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`เวลาในการนำจ่ายของบริษัทขนส่ง 09.00น.-20.00น.`, 4, total, null, null, 'left')
                        total += spacer
                        total += spacer
                        doc.text(`ลิงค์ติดตามสถานะการจัดส่ง`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`สินค้าเข้าระบบ  ${ data[i].tracking_time }น.`, 4, total, null, null, 'left')
                        total += spacer
                        doc.text(`${ data[i].link }`, 4, total, null, null, 'left')
                    },
                })
                doc.addPage(1)
            }
            doc.deletePage(data.length + 1)
            frame.value = doc.output('datauristring', { filename: `RECEIPT.pdf` })
        }

        return { savePDF, frame }
    },
}
</script>
